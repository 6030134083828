/** @jsx jsx */

import Link from '../components/Link'
import "@fontsource/roboto"
import Layout from '../components/layout'
import { FaFacebook, FaInstagram } from 'react-icons/fa'
import MySEO from '../components/seo'
import { jsx, Text, Flex, Heading, useColorMode } from 'theme-ui'
import YAMLData from "../data/navi.yaml"
import YAMLHaupt from "../data/hauptnavi.yaml"
import { useSpring, animated } from 'react-spring'
import { Container, Box } from 'theme-ui'
import { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'





const MyHome = () => {


  const [news, setNews] = useState(false)
  const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0, left: 0, color: "black", }, config: { duration: 500 } })

  const newsprops = useSpring({ to: { opacity: 1, x: 0 }, from: { opacity: 0, x: 400, maxWidth: 400 }, config: { duration: 900 } })
  const [colorMode] = useColorMode()
  const isDark = colorMode === `dark`
  return (
    <Layout>
      <MySEO title="Startseite Kulturnetz Oberes Mittelrheintal" description="Kulturnetz Oberes Mittelrheintal Startseite" keywords="K.O.M,KOM,Kulturnetz, Oberes Mittelrheintal,Bacharach, Katrin Gloggengiesser" />

      {
        news &&
        <Container
          sx={{ position: 'absolute', display: 'flex', flexDirection: 'column', width: [320, 600, 600, 800], justifyContent: 'flex-start', height: 1000, top: 365, zIndex: 300, right: 0, left: 0 }}
        >
          <Box
            sx={{ color: 'white', cursor: 'pointer' }}
            onKeyDown={() => setNews(!news)} onClick={() => setNews(!news)} as="Button"
          >Newsletter-Formular schließen
          </Box>
          <iframe title="Newsletter-Anmeldeformular" width="100%" height="800" src="https://e637a942.sibforms.com/serve/MUIEAK2nWTixVVm70Bak5JNdhAOOZGB2YtURdD6Dvq5I4_-ugscyK4mQ024n6tuLyuQFjZstAgY90gyAUBTRQgNYlcOgrWBeuLwEACN1Da_O8KFVbp7o54t4xprwQ7zIGeJP7yPAP6s3Y6Ga72_ifeR-3xmtqlR5pPitVOOD2tOt_vx4uTbBjrb9pR2OKt1FedUsdghnAOmaU41x" frameborder="0" scrolling="auto" allowfullscreen style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%' }}></iframe>
        </Container>
      }
      <Container sx={{ height: "100vh", m: 0, pl: 0, pb: [2, 3, 4], justifyContent: 'flex-start' }}>


        <Flex sx={{ flexDirection: ['column', 'column', 'row'], pb: 3 }}>

          {/* <Flex sx={{flexWrap:'wrap',fontSize:'1rem',my:3,mb:5}}> */}



          <Box>
            {



              new Date('2025-09-09') > Date.now() ?
                <animated.div style={newsprops}>
                  <Box sx={{ ml: 0, my: 2, py: 1, px: 1, flexShrink: 0, maxWidth: 390 }}>

                    <Heading sx={{ margin: 0 }}>Vortragsreihe von Prof. Dr. Dieter Kramer

                      <Text mb="0" mp="0">Die UNESCO und das Welterbe 'Oberes Mittelrheintal'</Text>


                    </Heading>
                    <Text mb="0" mt="2" mp="0">
                      Beginn: Dienstag, 8. April 2025 18 Uhr, Hotel Weinhaus Weiler Oberwesel, Marktplatz. Ab Juni 2025 wöchentlich (voraussichtlich), insgesamt 10 Treffen. Gebühr für alle Vorträge € 80, Einzelvortrag € 10

                    </Text>
                    <a href="/pdf/UNESCO.pdf" rel="nofollow noreferrer noopener" target="_blank"><Text sx={{ fontSize: 0 }}>Vorläufige Übersicht</Text></a>

                  </Box>

                </animated.div>

                : null
            }

          </Box>
          <Box>
            {



              new Date('2024-11-15') > Date.now() ?
                <animated.div style={newsprops}>
                  <Box sx={{ ml: 0, my: 2, py: 1, px: 1, flexShrink: 0, maxWidth: 390 }}>

                    <Heading sx={{ margin: 0 }}>Einladung zur Teilnahme am Brentano-Kolloquium in Koblenz am 14. November 2024
                      <Text> Thema: Die Entstehung der Rheinromantik: Brentano, Heine, Turner und die Folgen ...
                      </Text>




                    </Heading>


                    <Link to="/veranstaltung"><Text sx={{ fontSize: 0 }}>mehr</Text></Link>
                  </Box>

                </animated.div>

                : null
            }

          </Box>
          <Box>
            {
              new Date('2025-03-24') > Date.now() ?
                <animated.div style={newsprops}>
                  <Box sx={{ ml: 0, my: 2, py: 1, px: 1, flexShrink: 0, maxWidth: 390 }}>
                    <Heading sx={{ margin: 0 }}>
                      Von fremden Ländern und Menschent</Heading>
                    <Text mt="2" mb="0" mp="0">
                      Inspiriert von dem berühmten Klavierstück aus den „Kinderszenen" op. 15 von Robert Schumann
                      lädt das Collegium Vocale Koblenz unter der Leitung von Richard Moser und Matthias Frey zu einer musikalischen Reise um die Welt ein.</Text>
                    <Text mt="3">
                      Samstag | 22. März 2025 | 19:00 Uhr<br />Dorfgemeinschaftshaus Hahnstätten
                    </Text>
                    <Text>Sonntag | 23. März 2025 | 18:00 Uhr<br />
                      Saisoneröffnung Sayner Hütte <br />(Krupp'sche Halle)</Text>
                    {/* <Link to="/veranstaltung" rel="noopener nofollow noreferrer" target='_blank'><Text sx={{ fontSize: 0 }}>mehr</Text></Link> */}
                  </Box>

                </animated.div>

                : null
            }

          </Box>

          <Box>
            {
              new Date('2025-05-25') > Date.now() ?
                <animated.div style={newsprops}>
                  <Box sx={{ ml: 0, my: 2, py: 1, px: 1, flexShrink: 0, maxWidth: 390 }}>

                    <Heading sx={{ margin: 0 }}>The Last Days of Magic  </Heading>
                    <Text mb="0" mp="0">Stilleben zeitgenössischer Kunst</Text>
                    <Text mb="0" mp="0">Museum Boppard<br />Eröffnung am 18.01. von 18:00 bis 21:00<br />
                      Ausstellung vom 19.01.25 - 04.05.25</Text>
                    <a href="https://museum-boppard.de/" rel="nofollow noopener noreferrer target=" _blank><Text sx={{ fontSize: 0 }}>Webseite</Text></a>
                  </Box>

                </animated.div>

                : null
            }

          </Box>
          <Box>
            {
              new Date('2025-03-31') > Date.now() ?
                <animated.div style={newsprops}>
                  <Box sx={{ ml: 0, my: 2, py: 1, px: 1, flexShrink: 0, maxWidth: 390 }}>

                    <Heading sx={{ margin: 0 }}>Am Rheine schweb ich her und hin </Heading>
                    <Text mb="0" mp="0">Schauspiel mit viel Musik des taw Koblenz</Text>
                    <Text mb="0" mp="0">Samstag am 29.03. um 19:00 &amp; Sonntag am 30.3. um 17:00 in der Galerie Handwerk,<br />Rizzastr. 24-26 in Koblenz
                    </Text>
                    <Text>Eintritt 18 €, Reservierung unter taw@heldermannkoblenz.de | 0261 1005811</Text>

                  </Box>

                </animated.div>

                : null
            }

          </Box>

        </Flex>



        {/* <animated.div style={newsprops}>
  <Box sx={{mb:0, py:1,px:1,flexDirection:"row"}}>
   
    <Link style={{textDecoration:'none',fontWeight:100}} href="https://freili.netlify.app" 
    rel="noopener noreferrer" target="_blank">
    
      <Text  sx={{'&:hover':{color: "black"},mt:2,py:0,fontWeight:100,color:'black',fontSize:1}}>
    Besuchen Sie unsere neue Projekt-Seite:

    </Text>
    {
     !isDark?
     <StaticImage src="../images/freili-black.png" layout="fixed"  width={220} alt="ButtonBild" />:
     <StaticImage src="../images/freili-white.png" layout="fixed"  width={220} alt="ButtonBild" />
    }
      
  
    

    
    </Link>
    
  </Box> 
  </animated.div>
  <hr/> */}




        {/* </Flex> */}

        <div sx={{ position: "relative", mt: 3 }}>
          <animated.ul style={props}
            aria-label="Hauptmenu"
            sx={{
              listStyle: `none`,
              justifyContent: `flex-start`,
              alignItems: `flex-start`,
              m: 0,
              mt: [-4, -3, -2],
              ml: 0,
              li:
              {
                mx: 0,
                pb: 2,
                fontSize: [3, 5, 6],
                lineHeight: [1, 1.1],
                fontWeight: 400,
                cursor: `pointer`,
                a: {
                  px: 0,
                  pm: 0
                }
              }
            }}>

            {YAMLHaupt.content.map((data, index) => {
              return <li key={`content_item_${index}`}>
                <Link sx={{
                  color: `text`,
                  mx: 0,
                  px: 0,
                  fontWeight: 400,
                  '&:hover': {
                    color: `tomato`
                  },
                  textDecoration: `none !important`,
                  fontFamily: `Avenir Next`
                }}
                  to={data.link}>{data.text}</Link></li>
            })}
          </animated.ul>
          <animated.div style={newsprops}>
            <Box sx={{ mb: 0, py: 1, px: 1, flexDirection: "row" }}>

              <Link style={{ textDecoration: 'none', fontWeight: 100 }} href="https://freili.org"
                rel="noopener noreferrer" target="_blank">

                <Text sx={{ '&:hover': { color: "black" }, mt: 2, py: 0, fontWeight: 100, color: 'black', fontSize: 1 }}>
                  Besuchen Sie unsere neue Projekt-Seite:

                </Text>
                {
                  !isDark ?
                    <StaticImage src="../images/freili-black.png" layout="fixed" width={220} alt="ButtonBild" /> :
                    <StaticImage src="../images/freili-white.png" layout="fixed" width={220} alt="ButtonBild" />
                }





              </Link>

            </Box>
          </animated.div>
          <hr />

        </div>
        <button sx={{ ':hover': { color: 'tomato' }, marginTop: 40, color: "black", border: 'none', fontWeight: 500, backgroundColor: '#f6f5f7', cursor: 'pointer', fontSize: 1, letterSpacing: '0.05rem' }} onClick={() => setNews(!news)}>NEWSLETTER  abonnieren</button>


        <div sx={{ display: `flex`, justifyContent: `flex-start`, alignItems: `flex-start`, flexDirection: `row`, flexWrap: `wrap`, mt: 5, mb: 2 }}>
          <ul aria-label="Menu für Pflichtseiten" sx={{
            display: `inline-flex`,
            fontFamily: 'Roboto',
            listStyle: `none`,
            p: 0, mt: [20, 50, 180], mx: 0,
            flexDirection: `row`,

            flexWrap: `wrap`,
            width: `100%`,

          }}>
            <li><Link href="https://facebook.com/kulturnetzKOM" rel="noopener noreferrer" target="_blank" sx={{
              fontSize: [0, 1], marginRight: "20px", textTransform: `uppercase`, color: `text`, '&:hover': {
                color: `tomato`
              }
            }}><FaFacebook fontSize="1.3rem" /></Link></li>
            <li><Link href="https://www.instagram.com/kulturnetz_kom/?hl=de" rel="noopener noreferrer" target="_blank" sx={{
              fontSize: [0, 1], marginRight: "20px", textTransform: `uppercase`, color: `text`, '&:hover': {
                color: `tomato`
              }
            }}><FaInstagram fontSize="1.3rem" /></Link></li>
            {YAMLData.content.map((data, index) => {
              return <li key={`content_item_${index}`}> <Link sx={{
                color: `text`,
                textDecoration: `none`,
                fontFamily: `Roboto`,
                lineHeight: 1.3,
                pr: 3, m: 0,
              }} to={data.link}><span sx={{
                fontSize: [0, 1], textTransform: `uppercase`, color: `text`, '&:hover': {
                  color: `tomato`,
                },
              }}>{data.link}</span></Link></li>
            })}
          </ul>



        </div>
      </Container>



    </Layout>
  )
}


export default MyHome